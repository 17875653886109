<template>
  <el-col :span="24">
    <el-row>
      <h2 class="title-2 text-center text-uppercase">
        Datos de la empresa
      </h2>
    </el-row>
    <el-row class="center-block">
      <el-col
        :offset="1"
        :span="22"
      >
        <el-form
          :ref="reference"
          :model="formulario2"
          status-icon
          :rules="rules_formulario2"
        >
          <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
          >
            <el-form-item
              prop="rut"
              :error="errors.get('rut')"
            >
              <el-col :span="24">
                <el-input
                  v-model="formulario2.rut"
                  type="text"
                  auto-complete="no"
                  placeholder="RUT"
                  @change="errors.clear('rut')"
                />
              </el-col>
            </el-form-item>
            <el-form-item
              prop="company"
              :error="errors.get('company')"
            >
              <el-col :span="24">
                <el-input
                  v-model="formulario2.company"
                  type="text"
                  auto-complete="no"
                  placeholder="Nombre Fantasia"
                  @change="errors.clear('company')"
                />
              </el-col>
            </el-form-item>
            <el-form-item
              prop="razonsocial"
              :error="errors.get('razonsocial')"
            >
              <el-col :span="24">
                <el-input
                  v-model="formulario2.razonsocial"
                  type="text"
                  auto-complete="no"
                  placeholder="Razon Social"
                  @change="errors.clear('razonsocial')"
                />
              </el-col>
            </el-form-item>
          </el-col>
          <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
          >
            <el-form-item
              prop="comienzoactividad"
              :error="errors.get('comienzoactividad')"
            >
              <el-col :span="24">
                <el-date-picker
                  v-model="formulario2.comienzoactividad"
                  type="date"
                  placeholder="Comienzo de Actividades"
                  format="dd-MM-yyyy"
                  value-format="dd-MM-yyyy"
                  @change="errors.clear('comienzoactividad')"
                />
              </el-col>
            </el-form-item>
            <el-form-item
              prop="rubro_id"
              :error="errors.get('rubro_id')"
              class="bordered"
            >
              <el-col :span="24">
                <el-select
                  v-model="formulario2.rubro_id"
                  placeholder="Rubro"
                >
                  <el-option
                    v-for="item in rubros"
                    :key="item.id"
                    :label="item.rubro"
                    :value="item.id"
                  />
                </el-select>
              </el-col>
            </el-form-item>
            <el-form-item
              prop="direccion"
              :error="errors.get('direccion')"
            >
              <el-col :span="24">
                <el-input
                  v-model="formulario2.direccion"
                  type="text"
                  auto-complete="no"
                  placeholder="Direccion"
                  @change="errors.clear('direccion')"
                />
              </el-col>
            </el-form-item>
          </el-col>

          <el-col
            :span="24"
            class="center-block text-center"
          >
            <pz-back-button />
            <el-button
              round
              class="green-dark"
              @click="addSocios"
            >
              AGREGAR SOCIOS
            </el-button>
            <pz-forward-button :forward="handleSave" />
          </el-col>

          <el-col
            v-if="formulario2.socios.length>0"
            :span="24"
            class="el-row"
            style="text-align : center;width:100%; color:white; font-size: medium;"
          >
            <table class="table">
              <thead>
                <tr>
                  <td class="s-small">
                    <strong>Documento de Identidad</strong>
                  </td>
                  <td class="s-small">
                    <strong>Nombre</strong>
                  </td>
                  <td class="s-small">
                    <strong>Apellido</strong>
                  </td>
                  <td />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(socio, index) in formulario2.socios"
                  :key="index"
                >
                  <td>
                    <el-form-item
                      :key="socio.key"
                      :prop="'socios.' + index + '.documento'"
                      :rules="rules_socios.documento"
                    >
                      <el-input
                        v-model="socio.documento"
                        type="text"
                        auto-complete="no"
                        placeholder="Documento"
                      />
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item
                      :key="socio.key"
                      :prop="'socios.' + index + '.nombre'"
                      :rules="rules_socios.nombre"
                    >
                      <el-input
                        v-model="socio.nombre"
                        type="text"
                        auto-complete="no"
                        placeholder="Nombre"
                      />
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item
                      :key="socio.key"
                      :prop="'socios.' + index + '.apellido'"
                      :rules="rules_socios.apellido"
                    >
                      <el-input
                        v-model="socio.apellido"
                        type="text"
                        auto-complete="no"
                        placeholder="Apellido"
                      />
                    </el-form-item>
                  </td>

                  <td>
                    <el-button
                      type="danger"
                      class="el-dele-socios"
                      icon="el-icon-delete"
                      circle
                      @click="removeSocios(index)"
                    />
                    <!--                                    <a  style="cursor: pointer"><i-->
                    <!--                                        style="color:#F56C6C"-->
                    <!--                                        class="icon el-icon-remove"></i></a>-->
                  </td>
                </tr>
              </tbody>
            </table>
          </el-col>
        </el-form>
      </el-col>
    </el-row>
  </el-col>
</template>
<style type="scss">
    .el-dele-socios {
        font-size: 14px;
        padding: 8px !important;
    }

</style>
<script>


    import {Cedula, required, Rut} from "@/commons/utils/Rules";
    import 'element-ui/lib/theme-chalk/display.css'
    import {getDepartamentos, getRubros} from '@/routes/api/resources';
    import baseForm2 from "../baseForm2";
    import PzBackButton from "@/commons/forms/Buttons/PzBackButton";
    import PzForwardButton from "@/commons/forms/Buttons/PzForwardButton";

    export default {
        name: 'PzCompanyFormExtMx',
        components: {PzForwardButton, PzBackButton},
        mixins: [baseForm2],

        data() {
            return {
                loading: false,

                marital_status: [],
                rubros: [],
                sociosActivo: false,
                centerDialogVisible: false,
                departamentos: [],
                formulario2: {
                    company: '',
                    razonsocial: '',
                    rut: '',
                    comienzoactividad: '',
                    direccion: '',
                    rubro_id: '',
                    ref_empresa: process.env.COMPANY_KEY,
                    socios: [],
                },
                disable: true,
                rules_formulario2:
                    {
                        company:
                            [
                                required('')
                            ],
                        razonsocial:
                            [
                                required('')
                            ],
                        rut:
                            [
                                Rut('')
                            ],
                        comienzoactividad:
                            [
                                required('')
                            ],

                        rubro_id:
                            [
                                required('')
                            ],

                    },
                rules_socios: {
                    nombre: [required('')],
                    apellido: [required('')],
                    documento: [Cedula('')],
                }

            };
        },

        created() {
            let depto = getDepartamentos().then((res) => {
                this.departamentos = res.data;
            }).catch(() => {
                this.departamentos = [];
            });
            let rubros = getRubros().then((res) => {
                this.rubros = res.data;
            }).catch(() => {
                this.rubros = [];
            });
            Promise.all([rubros, depto]).then(() => this.$store.dispatch('app/downLoad'));

        },

        methods: {
            removeSocios: function (index) {
                this.formulario2.socios.splice(index, 1);
            },
            addSocios() {
                this.sociosActivo = true;
                // var elem = document.createElement('tr');
                this.formulario2.socios.push({
                    nombre: '',
                    apellido: '',
                    documento: '',
                    key: ''
                });
            },
        },


    }


</script>

