<template>
  <pz-form-flujo
    v-model="formulario2['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario2"
      status-icon
      :rules="rules_formulario2"
    >
      <pz-direction v-model="formulario2.direccion" />
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="telefono_fijo"
            :label="$t('forms_flujo.form2_persona.MX.phone')"
            :error="errors.get('telefono_fijo')"
          >
            <el-input
              v-model="formulario2.telefono_fijo"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.MX.phone')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        :gutter="20"
        class="pz-large-margin"
      >
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
          class="row justify-content-end"
        >
          <b>
            {{ $t('forms_flujo.form2_persona.MX.contact.title_personal') }}
          </b>
        </el-col>
      </el-row>
      <el-col :span="24">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="personal"
            :error="errors.get('personal')"
          >
            <el-col :span="12">
              <el-input
                v-model="formulario2.personal.nombre_completo"
                :type="'text'"
                :label="$t('forms_flujo.form2_persona.MX.contact.personal_full_name')"
                auto-complete="no"
                :placeholder="$t('forms_flujo.form2_persona.MX.contact.personal_full_name')"
              />
            </el-col>
            <el-col :span="12">
              <el-input
                v-model="formulario2.personal.direccion"
                :type="'text'"
                :label="$t('forms_flujo.form2_persona.MX.contact.personal_address')"
                auto-complete="no"
                :placeholder="$t('forms_flujo.form2_persona.MX.contact.personal_address')"
              />
            </el-col>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="personal"
            :error="errors.get('personal')"
          >
            <el-col :span="12">
              <el-input
                v-model="formulario2.personal.telefono"
                :type="'text'"
                :label="$t('forms_flujo.form2_persona.MX.contact.personal_phone')"
                auto-complete="no"
                :placeholder="$t('forms_flujo.form2_persona.MX.contact.personal_phone')"
              />
            </el-col>
            <el-col :span="12">
              <el-input
                v-model="formulario2.personal.parentesco"
                :type="'text'"
                :label="$t('forms_flujo.form2_persona.MX.contact.personal_parentship')"
                auto-complete="no"
                :placeholder="$t('forms_flujo.form2_persona.MX.contact.personal_parentship')"
              />
            </el-col>
          </el-form-item>
        </el-col>
      </el-col>
      <el-row
        :gutter="20"
        class="pz-large-margin"
      >
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
          class="row justify-content-end"
        >
          <b>
            {{ $t('forms_flujo.form2_persona.MX.contact.title_familiar') }}
          </b>
        </el-col>
      </el-row>
      <el-col :span="24">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="personal"
            :error="errors.get('personal')"
          >
            <el-col :span="12">
              <el-input
                v-model="formulario2.familiar.nombre_completo"
                :type="'text'"
                :label="$t('forms_flujo.form2_persona.MX.contact.familiar_full_name')"
                auto-complete="no"
                placeholder="Nombre y apellido"
              />
            </el-col>
            <el-col :span="12">
              <el-input
                v-model="formulario2.familiar.direccion"
                :type="'text'"
                auto-complete="no"
                :placeholder="$t('forms_flujo.form2_persona.MX.contact.familiar_full_name')"
              />
            </el-col>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="personal"
            :error="errors.get('personal')"
          >
            <el-col :span="12">
              <el-input
                v-model="formulario2.familiar.telefono"
                :type="'text'"
                :label="$t('forms_flujo.form2_persona.MX.contact.familiar_phone')"
                auto-complete="no"
                :placeholder="$t('forms_flujo.form2_persona.MX.contact.familiar_phone')"
              />
            </el-col>
            <el-col :span="12">
              <el-input
                v-model="formulario2.familiar.parentesco"
                :type="'text'"
                :label="$t('forms_flujo.form2_persona.MX.contact.familiar_parentship')"
                auto-complete="no"
                :placeholder="$t('forms_flujo.form2_persona.MX.contact.familiar_parentship')"
              />
            </el-col>
          </el-form-item>
        </el-col>
      </el-col>
    </el-form>
  </pz-form-flujo>
</template>
<script>

    import {required, type} from "@/commons/utils/Rules";
    import {getLocalidad, getDepartamentos, getMunicipios} from "@/routes/api/resources";
    import baseform2 from '../baseForm2'
    import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
    import PzDirection from "@/commons/forms/Fields/PzDirection";

    export default {
        name: 'PzPersonFormExtMx',
        components: {PzDirection, PzFormFlujo},
        mixins: [baseform2],
        data() {
            return {
                properties: [],
                provincias: [],
                employment_relations: [],
                studies: [],
                municipios: [],
                localidad: [],
                formas_de_enterarse: [],
                formulario2: {
                    tipo_vivienda: '',
                    telefono_fijo: '',
                    direccion: {},
                    mail: '',
                    telefono_empresa: '',
                    estudio: '',
                    relacion_laboral: '',
                    persona_contacto: '',
                    telefono_persona_contacto: '',
                    comoseentero: '',
                    url_trackeo: '',
                    ref_empresa: process.env.COMPANY_KEY,
                    tipo_documento: process.env.IDE_DEFAULT,
                    codigo_postal: '',
                    personal: {nombre_completo: '', telefono: '', direccion: '', parentesco: ''},
                    familiar: {nombre_completo: '', telefono: '', direccion: '', parentesco: ''},
                }
                ,
                disable: true,
                rules_formulario2:
                    {

                        telefono_fijo: [
                            required('')
                        ],
                        mail: [
                            type({field: '', type: 'email'})
                        ],
                    },
                pickerOptions1: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                },
            }
        },
        created: function () {
            //this.fetchData();
        },
        methods: {
            fetchData() {
                this.$store.dispatch('loader/up',{trigger:this.$options.name});
                let deptos = getDepartamentos().then((res) => {
                    this.provincias = res.data;
                }).catch(() => {
                    this.provincias = [];
                });
                let municipios = this.getMunicipio();

                let localidades = this.getLocalidad();
                Promise.all([deptos, municipios, localidades]).
                    finally(() => {this.$store.dispatch('loader/down',{trigger:this.$options.name})});
            },
            getMunicipio() {
                getMunicipios(this.formulario2.nivel2).then((res) => {
                    this.municipios = res.data;
                }).catch(() => {
                    this.municipios = [];
                });
            },
            getLocalidad() {
                getLocalidad(this.formulario2.nivel3).then((res) => {
                    this.localidad = res.data;
                }).catch(() => {
                    this.localidad = [];
                });
            }
        },
    }
</script>

